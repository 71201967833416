import SvgFile from '@/components/icons/SvgFile';
import {Label3} from '@/components/texts/Label';
import {Skeleton} from '@/components/views/Skeleton';
import {ProfileListItem} from '@/modules/profile/components/ProfileListItem';
import {ProfileSelectInput} from '@/modules/profile/components/ProfileSelectInput';
import {useGetSelfUserQuery, useUpdateUserMutation} from '@/store/queries/usersApi';
import {SelectInputItem} from '@/types/FormInputProps';
import {useTranslate} from '@tolgee/react';
import {useCallback, useMemo} from 'react';
import {View, XStack, YStack, isTouchable, useMedia} from 'tamagui';

export function CommunicationLanguageInput() {
  const {t} = useTranslate();
  const media = useMedia();
  // TODO: implement optimistic update
  const {data: user} = useGetSelfUserQuery();
  const [updateUser] = useUpdateUserMutation();
  const items = useMemo(
    () =>
      [
        {
          key: 'de',
          name: t('SETTINGS.LANGUAGE.DE'),
          icon: 'flag-de',
        },
        {
          key: 'en',
          name: t('SETTINGS.LANGUAGE.EN'),
          icon: 'flag-gb',
        },
      ] satisfies SelectInputItem[],
    [t]
  );

  const infoBlock = useMemo(() => {
    if (media.sm || isTouchable) return null;
    return (
      <XStack gap="$2" flexGrow={0} flexShrink={1} marginBottom="$1">
        <SvgFile name="info" color="$primary" size={20} />
        <YStack gap="$1" flexGrow={0}>
          <Label3 color="$primary">{t('SETTINGS.COMMUNICATION_MODE.WHY.TITLE')}</Label3>
          <Label3>{t('SETTINGS.COMMUNICATION_MODE.WHY.DESCRIPTION')}</Label3>
        </YStack>
      </XStack>
    );
  }, [media.sm, t]);

  const additionalContent = useMemo(() => {
    if (!media.sm && !isTouchable) return undefined;
    return (
      <XStack gap="$2" padding="$2">
        <SvgFile name="info" color="$primary" size={20} />
        <YStack gap="$1" flex={1}>
          <Label3 color="$primary">{t('SETTINGS.COMMUNICATION_MODE.WHY.TITLE')}</Label3>
          <Label3>{t('SETTINGS.COMMUNICATION_MODE.WHY.DESCRIPTION')}</Label3>
        </YStack>
      </XStack>
    );
  }, [media.sm, t]);

  const selectInputChildren = useMemo(() => {
    if (!media.sm && !isTouchable) return null;
    const value = t(`SETTINGS.LANGUAGE.${user?.preferredLanguage?.toUpperCase()}`) || '-';
    return <ProfileListItem label={t('SETTINGS.SELECT_COMMUNICATION_LANGUAGE')} value={value} />;
  }, [media.sm, t, user?.preferredLanguage]);

  const onSelect = useCallback(
    (item: string) => {
      if (user) updateUser({currentUser: user, changes: {preferredLanguage: item}});
    },
    [updateUser, user]
  );

  return (
    <Skeleton width={300}>
      {user && (
        <View
          flexDirection="row"
          alignItems="flex-end"
          $sm={{flexDirection: 'column', alignItems: 'stretch'}}
          justifyContent="space-between"
          flexWrap="wrap"
          gap="$4"
        >
          <ProfileSelectInput
            label={t('SETTINGS.SELECT_COMMUNICATION_LANGUAGE')}
            items={items}
            placeholder="-"
            onSelect={onSelect}
            selectedItem={user.preferredLanguage}
            additionalContent={additionalContent}
          >
            {selectInputChildren}
          </ProfileSelectInput>
          {infoBlock}
        </View>
      )}
    </Skeleton>
  );
}
