import {isTouchable, useMedia, YStack} from 'tamagui';

import {ResponsiveScrollView} from '@/components/views/ResponsiveScrollView';
import {EasUpdateBadge} from '@/modules/app/components/EasUpdateBadge';
import {WebFooter} from '@/modules/navigation/components/footer/WebFooter';
import CreateNewAccountButton from '@/modules/onboarding/components/CreateNewAccountButton';
import {AccountList} from '@/modules/overview/components/AccountList';
import {AccountOrderList} from '@/modules/overview/components/AccountOrderList';
import {HomeGreeting} from '@/modules/overview/components/HomeGreeting';
import {HomeHeader} from '@/modules/overview/components/HomeHeader';
import {TermsAndConditions} from '@/modules/terms-and-conditions/components/TermsAndConditions';
import {useAppDispatch, useAppSelector} from '@/store';
import {setDeeplink} from '@/store/reducers/app';
import {useRouter} from 'expo-router';
import {useEffect} from 'react';

export default function HomePage() {
  const media = useMedia();
  const deeplink = useAppSelector(state => state.app.deeplink);
  const router = useRouter();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (deeplink) {
      router.navigate(deeplink);
      dispatch(setDeeplink());
    }
  }, [deeplink, dispatch, router]);

  return (
    <ResponsiveScrollView
      contentContainerStyle={{paddingBottom: '$2', paddingTop: media.gtSm ? '$7' : 0}}
    >
      <YStack gap="$5">
        <HomeGreeting />
        <YStack $gtSm={{gap: '$5'}}>
          <HomeHeader />
          <YStack paddingHorizontal={media.sm || isTouchable ? '$4' : '$0'} gap="$4">
            <EasUpdateBadge />
            <AccountList />
            <AccountOrderList />
            <CreateNewAccountButton />
          </YStack>
        </YStack>
        <TermsAndConditions />
      </YStack>

      <WebFooter withCalendly />
    </ResponsiveScrollView>
  );
}
