import {useTranslate} from '@tolgee/react';
import {Stack} from 'expo-router';
import React, {useMemo} from 'react';
import {Platform} from 'react-native';

import {Card} from '@/components/cards/Card';
import {ResponsiveScrollView} from '@/components/views/ResponsiveScrollView';
import {MFARequiredNote} from '@/modules/mfa/components/MFARequiredNote';
import {WebFooter} from '@/modules/navigation/components/footer/WebFooter';
import {Header} from '@/modules/navigation/components/header/Header';
import {Tabbar} from '@/modules/navigation/components/tabbar/Tabbar';
import {GermanTaxIDCard} from '@/modules/profile/components/taxes/GermanTaxIDCard';
import {PreviousTaxes} from '@/modules/profile/components/taxes/PreviousTaxes';
import {TaxExemptionCards} from '@/modules/profile/components/taxes/TaxExemptionCards';
import {TaxExemptionNote} from '@/modules/profile/components/taxes/TaxExemptionNote';
import {useGetCustomerAccountsQuery} from '@/store/queries/customersApi';
import {YStack} from 'tamagui';

export default function TabTaxes() {
  const {t} = useTranslate();
  const {data: customers} = useGetCustomerAccountsQuery();
  const hasChildAccount = useMemo(() => {
    return customers?.items.some(item => item.product === 'CHILD_ACCOUNT');
  }, [customers]);
  const hasOnlyChildAccounts = useMemo(() => {
    const dabCustomers = customers?.items.filter(_ => _.custodian === 'DAB');

    return (
      dabCustomers &&
      dabCustomers.length > 0 &&
      dabCustomers.every(item => item.product === 'CHILD_ACCOUNT')
    );
  }, [customers]);

  const elements = [
    {label: t('TAXES.TAX-EXEMPTION.ACTUAL')},
    {label: t('TAXES.TAX-EXEMPTION.PREVIOUS')},
  ];

  return (
    <ResponsiveScrollView>
      <YStack gap="$5">
        <Stack.Screen
          options={{
            headerShown: true,
            header: () => (
              <Header title={t('NAVIGATION.TAX.TITLE')} backButton={Platform.OS !== 'web'} />
            ),
          }}
        />
        <GermanTaxIDCard />
        <Card headerText={t('TAXES.TAX-EXEMPTION')} gap="$5" padding="$5">
          <MFARequiredNote />
          {hasChildAccount === true && <TaxExemptionNote />}
          <Tabbar elements={elements}>
            <TaxExemptionCards newTaxExemptionDisabled={hasOnlyChildAccounts === true} />
            <PreviousTaxes />
          </Tabbar>
        </Card>
      </YStack>
      <WebFooter withCalendly />
    </ResponsiveScrollView>
  );
}
