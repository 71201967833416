import {Label2} from '@/components/texts/Label';
import LocalizedNumbers from '@/components/texts/LocalizedNumbers';
import {Skeleton} from '@/components/views/Skeleton';
import React from 'react';
import {View, ViewProps} from 'tamagui';

type NumberBadgeProps = {
  value: number | undefined;
  color?: ViewProps['backgroundColor'];
};

export function NumberBadge({value, color = '$primary'}: NumberBadgeProps) {
  return (
    <Skeleton width={75}>
      {value !== undefined && (
        <View
          backgroundColor={color}
          alignSelf="flex-start"
          borderRadius="$0.5"
          paddingVertical="$1"
          paddingHorizontal="$2"
        >
          <LocalizedNumbers
            value={value}
            precision="integer"
            numberStyle={{color: '$neutral0', variant: 'medium'}}
            textComponent={Label2}
          />
        </View>
      )}
    </Skeleton>
  );
}
