import {yupResolver} from '@hookform/resolvers/yup';
import {useTranslate} from '@tolgee/react';
import {forwardRef, useEffect, useImperativeHandle, useMemo} from 'react';
import {useForm} from 'react-hook-form';
import {YStack} from 'tamagui';

import {FormAmountInput} from '@/components/inputs/form/FormAmountInput';
import {FormDepositMethodSelect} from '@/components/inputs/form/FormDepositMethodSelect';
import {BulletPoint} from '@/components/texts/BulletPoint';
import {Heading5} from '@/components/texts/Heading';
import {Label2} from '@/components/texts/Label';
import {Paragraph2} from '@/components/texts/Paragraph';
import {PartnerBank} from '@/constants/partner-bank';
import {useIsUpvest} from '@/hooks/useIsUpvest';
import {InitialDepositSchemaType, useValidations} from '@/hooks/useValidations';
import {OnboardingHintModal} from '@/modules/onboarding/components/OnboardingHintModal';
import {usePostHog} from '@/providers/posthog/usePostHog';
import {initialDepositMethod} from '@/types/api/orders';
import {Product} from '@/types/fixedTypes/customers.v2/Product';

type InitialDepositFormProps = {
  onValid: (data: InitialDepositSchemaType) => void;
  product: Product;
  defaultValues?: {amount: number; method: initialDepositMethod | typeof ZERO_DEPOSIT};
  setMethod: (method: initialDepositMethod | typeof ZERO_DEPOSIT) => void;
  setIsLoading?: (loading: boolean) => void;
};

export const ZERO_DEPOSIT = 'ZERO_DEPOSIT' as const;

export const InitialDepositForm = forwardRef<any, InitialDepositFormProps>(
  function InitialDepositFor({onValid, product, defaultValues, setMethod, setIsLoading}, ref) {
    const {t} = useTranslate();

    const isUpvest = useIsUpvest(product);
    const {initialDepositSchema} = useValidations();
    const {
      handleSubmit,
      control,
      watch,
      reset,
      trigger,
      formState: {isSubmitting},
    } = useForm({
      resolver: yupResolver(initialDepositSchema),
      context: {
        custodian: isUpvest ? PartnerBank.UPVEST : PartnerBank.DAB,
      },
      mode: 'onChange',
    });
    const posthog = usePostHog();

    useEffect(() => {
      if (!defaultValues) return;

      reset(defaultValues, {keepDirty: true, keepTouched: true});
    }, [reset, defaultValues]);

    useImperativeHandle(ref, () => ({
      submit: handleSubmit(onValid, data => {
        posthog?.capture('form_submit_failed', data);
      }),
    }));

    useEffect(() => {
      setIsLoading?.(isSubmitting);
    }, [isSubmitting, setIsLoading]);

    const methods = useMemo(() => {
      return [
        {
          code: initialDepositMethod.DIRECT_DEBIT,
          label: t('PERSONAL_DETAILS.INITIAL_DEPOSIT.OPTIONS.DIRECT_DEBIT'),
          caption: t('PERSONAL_DETAILS.INITIAL_DEPOSIT.OPTIONS.DIRECT_DEBIT_CAPTION'),
        },
        {
          code: initialDepositMethod.WIRE_DEBIT,
          label: t('PERSONAL_DETAILS.INITIAL_DEPOSIT.OPTIONS.WIRE_DEBIT'),
          caption: t('PERSONAL_DETAILS.INITIAL_DEPOSIT.OPTIONS.WIRE_DEBIT_CAPTION'),
        },
        {
          code: ZERO_DEPOSIT,
          label: t('PERSONAL_DETAILS.INITIAL_DEPOSIT.OPTIONS.ZERO_DEPOSIT'),
          caption: t('PERSONAL_DETAILS.INITIAL_DEPOSIT.OPTIONS.ZERO_DEPOSIT_CAPTION'),
        },
      ];
    }, [t]);

    const method = watch('method');

    const hintLabel = useMemo(() => {
      if (method === initialDepositMethod.DIRECT_DEBIT) {
        return t('PERSONAL_DETAILS.INITIAL_DEPOSIT.DIRECT_DEBT_INFO.TITLE');
      }
      if (method === initialDepositMethod.WIRE_DEBIT) {
        return t('PERSONAL_DETAILS.INITIAL_DEPOSIT.CREDIT_TRANSFER_INFO.TITLE');
      }
      return '';
    }, [method, t]);

    const hintContent = useMemo(() => {
      if (method === initialDepositMethod.DIRECT_DEBIT) {
        const points = isUpvest
          ? [
              t(`PERSONAL_DETAILS.INITIAL_DEPOSIT.DIRECT_DEBT_INFO.UPVEST.AUTOMATIC_DEBITS.TITLE`),
              t(`PERSONAL_DETAILS.INITIAL_DEPOSIT.DIRECT_DEBT_INFO.UPVEST.AUTOMATIC_DEBITS.DESC`),
              t(`PERSONAL_DETAILS.INITIAL_DEPOSIT.DIRECT_DEBT_INFO.UPVEST.DEPOSIT_LIMITS.TITLE`),
              t(`PERSONAL_DETAILS.INITIAL_DEPOSIT.DIRECT_DEBT_INFO.UPVEST.DEPOSIT_LIMITS.DESC`),
              t(`PERSONAL_DETAILS.INITIAL_DEPOSIT.DIRECT_DEBT_INFO.UPVEST.REFUND_POLICY.TITLE`),
              t(`PERSONAL_DETAILS.INITIAL_DEPOSIT.DIRECT_DEBT_INFO.UPVEST.REFUND_POLICY.DESC`),
            ]
          : [
              t(`PERSONAL_DETAILS.INITIAL_DEPOSIT.DIRECT_DEBT_INFO.DAB.AUTOMATIC_DEBITS.TITLE`),
              t(`PERSONAL_DETAILS.INITIAL_DEPOSIT.DIRECT_DEBT_INFO.DAB.AUTOMATIC_DEBITS.DESC`),
              t(`PERSONAL_DETAILS.INITIAL_DEPOSIT.DIRECT_DEBT_INFO.DAB.DEPOSIT_LIMITS.TITLE`),
              t(`PERSONAL_DETAILS.INITIAL_DEPOSIT.DIRECT_DEBT_INFO.DAB.DEPOSIT_LIMITS.DESC`),
              t(`PERSONAL_DETAILS.INITIAL_DEPOSIT.DIRECT_DEBT_INFO.DAB.REFUND_POLICY.TITLE`),
              t(`PERSONAL_DETAILS.INITIAL_DEPOSIT.DIRECT_DEBT_INFO.DAB.REFUND_POLICY.DESC`),
            ];
        return (
          <YStack gap="$4">
            <Heading5 variant="medium">
              {t('PERSONAL_DETAILS.INITIAL_DEPOSIT.DIRECT_DEBT_INFO.TITLE')}
            </Heading5>
            <Label2 color="$primary" variant="medium">
              {t('PERSONAL_DETAILS.INITIAL_DEPOSIT.DIRECT_DEBT_INFO.NOTE')}
            </Label2>

            <BulletPoint>
              <Paragraph2 variant="medium">{points[0]}</Paragraph2>
              <Paragraph2 color="$neutral400">{points[1]}</Paragraph2>
            </BulletPoint>

            <BulletPoint>
              <Paragraph2 variant="medium">{points[2]}</Paragraph2>
              <Paragraph2 color="$neutral400">{points[3]}</Paragraph2>
            </BulletPoint>

            <BulletPoint>
              <Paragraph2 variant="medium">{points[4]}</Paragraph2>
              <Paragraph2 color="$neutral400">{points[5]}</Paragraph2>
            </BulletPoint>
          </YStack>
        );
      }
      if (method === initialDepositMethod.WIRE_DEBIT) {
        return (
          <YStack gap="$4">
            <Heading5 variant="medium">
              {t('PERSONAL_DETAILS.INITIAL_DEPOSIT.CREDIT_TRANSFER_INFO.TITLE')}
            </Heading5>
            <Label2 color="$primary" variant="medium">
              {t('PERSONAL_DETAILS.INITIAL_DEPOSIT.CREDIT_TRANSFER_INFO.NOTE')}
            </Label2>

            <BulletPoint>
              <Paragraph2 color="$neutral400">
                {t(
                  'PERSONAL_DETAILS.INITIAL_DEPOSIT.CREDIT_TRANSFER_INFO.IDENTIFICATION_PROCESS_INFO'
                )}
              </Paragraph2>
            </BulletPoint>
          </YStack>
        );
      }
    }, [isUpvest, method, t]);

    useEffect(() => {
      if (method === ZERO_DEPOSIT) {
        reset({amount: 0, method: ZERO_DEPOSIT});
      }
      setMethod(method);
      trigger('amount');
    }, [method, reset, setMethod, trigger]);

    return (
      <YStack gap="$4">
        <FormAmountInput
          control={control}
          name="amount"
          label={t('PERSONAL_DETAILS.INITIAL_DEPOSIT.INPUT_LABEL')}
        />
        <FormDepositMethodSelect control={control} name="method" items={methods} />

        {method !== ZERO_DEPOSIT && (
          <OnboardingHintModal label={hintLabel}>{hintContent}</OnboardingHintModal>
        )}
      </YStack>
    );
  }
);
