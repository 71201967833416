import {useTranslate} from '@tolgee/react';
import {Stack} from 'expo-router';
import React from 'react';
import {Platform} from 'react-native';
import {useMedia, View, XStack, YStack} from 'tamagui';

import {Card} from '@/components/cards/Card';
import {Label3} from '@/components/texts/Label';
import {LocalizedDate} from '@/components/texts/LocalizedDate';
import {ResponsiveScrollView} from '@/components/views/ResponsiveScrollView';
import useRefdataLocalizable from '@/hooks/useRefdataLocalizable';
import {WebFooter} from '@/modules/navigation/components/footer/WebFooter';
import {Header} from '@/modules/navigation/components/header/Header';
import {AddressChangeCard} from '@/modules/profile/components/AddressChangeCard';
import {useAcademicTitlesQuery, useTitlesQuery} from '@/store/queries/referenceApi';
import {useGetSelfUserQuery} from '@/store/queries/usersApi';

export default function TabPersonalData() {
  const {t} = useTranslate();
  const {data: user} = useGetSelfUserQuery();
  const {data: titles} = useTitlesQuery();
  const {data: academicTitles} = useAcademicTitlesQuery();
  const {getByCode} = useRefdataLocalizable();
  const media = useMedia();

  return (
    <ResponsiveScrollView useKeyboardAwareScrollView>
      <YStack gap="$5">
        <Stack.Screen
          options={{
            headerShown: true,
            header: () => (
              <Header title={t('NAVIGATION.PROFILE.TITLE')} backButton={Platform.OS !== 'web'} />
            ),
          }}
        />
        <Card headerText={t('NAVIGATION.PROFILE.PERSONAL_DATA')} gap="$6" padding="$6">
          <View justifyContent="space-between" flexDirection={media.sm ? 'column' : 'row'} gap="$4">
            <XStack justifyContent="space-between" minWidth="45%" gap="$2">
              <YStack gap="$4">
                <Label3 color="$neutral500">{t('PERSONAL_DETAILS.SALUTATION')}</Label3>
                <Label3 color="$neutral500">{t('PERSONAL_DETAILS.FIRST_NAME')}</Label3>
                <Label3 color="$neutral500">{t('PERSONAL_DETAILS.LAST_NAME')}</Label3>
              </YStack>
              <YStack gap="$4" maxWidth={250}>
                <Label3 variant="medium" textAlign="right">
                  {user?.title && getByCode(titles, user?.title)?.label}
                </Label3>
                <Label3 variant="medium" textAlign="right">
                  {user?.firstName}
                </Label3>
                <Label3 variant="medium" textAlign="right">
                  {user?.lastName}
                </Label3>
              </YStack>
            </XStack>
            <XStack justifyContent="space-between" minWidth="45%" gap="$2">
              <YStack gap="$4">
                <Label3 color="$neutral500">{t('PERSONAL_DETAILS.ACADEMIC_TITLE')}</Label3>
                <Label3 color="$neutral500">{t('PERSONAL_DETAILS.DATE_OF_BIRTH')}</Label3>
                <Label3 color="$neutral500">{t('PERSONAL_DETAILS.PHONE_NUMBER')}</Label3>
              </YStack>
              <YStack gap="$4" maxWidth={250}>
                <Label3 variant="medium" textAlign="right">
                  {user?.academicTitle
                    ? getByCode(academicTitles, user?.academicTitle)?.label
                    : '-'}
                </Label3>
                {user?.dateOfBirth && (
                  <LocalizedDate
                    date={user?.dateOfBirth}
                    textComponent={Label3}
                    variant="medium"
                    textAlign="right"
                    color="$text"
                  />
                )}
                <Label3 variant="medium" textAlign="right">
                  {user?.phoneNumber}
                </Label3>
              </YStack>
            </XStack>
          </View>
        </Card>
        <AddressChangeCard />
      </YStack>
      <WebFooter withCalendly />
    </ResponsiveScrollView>
  );
}
