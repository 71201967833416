import {IconButton} from '@/components/buttons/IconButton';
import {LabelCard} from '@/components/cards/LabelCard';
import {Label3} from '@/components/texts/Label';
import {AddNumber} from '@/modules/mfa/components/AddNumber';
import {NotValidMfaMethodDesc} from '@/modules/mfa/components/NotValidMfaMethodDecsription';
import {RemainingTimeChip} from '@/modules/mfa/components/RemainingTimeChip';
import {useMfaAvailability} from '@/modules/mfa/hooks/useMfaAvailability';
import {MfaMethodSmsResponse} from '@/types/api/auth';
import {useTranslate} from '@tolgee/react';
import {XStack} from 'tamagui';

type Props = {
  methods: MfaMethodSmsResponse[];
  onAdd: (id?: string) => void;
  onRemove: (id: string) => void;
  loading?: boolean;
};

const MAX = 10;

export const PhoneNumbersCard = ({methods, onAdd, onRemove, loading}: Props) => {
  const {t} = useTranslate();
  const {invalidMethod} = useMfaAvailability();

  const handleRemoveNumber = (id: string) => onRemove(id);

  const handleAddNumber = (id?: string) => onAdd(id);

  return (
    <LabelCard gap="$3" label={t('MFA.PHONE_NUMBER')}>
      {methods.map((method, index, self) => (
        <XStack key={method.id} justifyContent="space-between" alignItems="center">
          <Label3>{method.phoneNumber}</Label3>
          {invalidMethod === undefined ? (
            <>
              {self.length === 1 ? (
                <AddNumber onPress={() => handleAddNumber()} />
              ) : (
                <IconButton
                  name="trash"
                  size={18}
                  color="$primary"
                  onPress={() => handleRemoveNumber(method.id)}
                />
              )}
            </>
          ) : (
            <RemainingTimeChip validDate={invalidMethod.time} />
          )}
        </XStack>
      ))}
      {invalidMethod !== undefined && <NotValidMfaMethodDesc />}
      {methods.length > 1 && methods.length < MAX && invalidMethod === undefined && (
        <AddNumber onPress={() => handleAddNumber()} />
      )}
    </LabelCard>
  );
};
