import {useTranslate} from '@tolgee/react';
import {Stack} from 'expo-router';
import React from 'react';

import {ResponsiveScrollView} from '@/components/views/ResponsiveScrollView';
import {WebFooter} from '@/modules/navigation/components/footer/WebFooter';
import {Header} from '@/modules/navigation/components/header/Header';
import {Recommendations} from '@/modules/recommendations/components/Recommendations';

export default function TabProfile() {
  const {t} = useTranslate();

  return (
    <ResponsiveScrollView>
      <Stack.Screen
        options={{
          headerShown: true,
          header: () => <Header title={t('NAVIGATION.RECOMMENDATIONS')} backButton={false} />,
        }}
      />
      <Recommendations />
      <WebFooter withCalendly />
    </ResponsiveScrollView>
  );
}
