import {Button} from '@/components/buttons/Button';
import {Modal} from '@/components/modal/Modal';
import {Heading4} from '@/components/texts/Heading';
import {Paragraph2} from '@/components/texts/Paragraph';
import {Skeleton} from '@/components/views/Skeleton';
import {BaseRecommendationCard} from '@/modules/recommendations/components/BaseRecommendationCard';
import {RecommendationModalBulletPoint} from '@/modules/recommendations/components/RecommendationModalBulletPoint';
import {usePostHog} from '@/providers/posthog/usePostHog';
import {useGetCustomerAccountsQuery} from '@/store/queries/customersApi';
import {
  useGetInterviewQuery,
  useGetLatestAcceptableInterviewsQuery,
} from '@/store/queries/interviewApi';
import {ModalRef} from '@/types/modal';
import {skipToken} from '@reduxjs/toolkit/query';
import {useTranslate} from '@tolgee/react';
import {useRouter} from 'expo-router';
import {useMemo, useRef} from 'react';
import {YStack} from 'tamagui';

export function SavingsrateRecommendationCard() {
  const {t} = useTranslate();
  const router = useRouter();
  const ref = useRef<ModalRef>(null);
  const {data: customers, isFetching: isCustomersFetching} = useGetCustomerAccountsQuery();
  const {data: interviews, isFetching: fetchingInterviews} =
    useGetLatestAcceptableInterviewsQuery();
  const {data: lastInterview, isFetching: fetchingParticularInterview} = useGetInterviewQuery(
    interviews?.[0]?.id !== undefined ? {id: interviews[0].id} : skipToken
  );
  const posthog = usePostHog();

  const lastMonthlyNetIncome = useMemo(() => {
    return lastInterview?.MONTHLY_NET_INCOME;
  }, [lastInterview?.MONTHLY_NET_INCOME]);

  const sumSavingsRates = useMemo(() => {
    return customers?.items.reduce((acc, curr) => {
      return acc + (curr?.currentRecurringDeposit?.amount || 0);
    }, 0);
  }, [customers?.items]);

  // Show when: SUM(Savings rate) all subaccounts < 20% latest interview MONTHLY_NET_INCOME
  if (
    customers?.items?.[0]?.id === undefined ||
    sumSavingsRates === undefined ||
    lastMonthlyNetIncome === undefined ||
    sumSavingsRates >= 0.2 * lastMonthlyNetIncome
  )
    return null;

  if (isCustomersFetching || fetchingInterviews || fetchingParticularInterview)
    return <Skeleton width={300} height={200} />;

  return (
    <Modal ref={ref}>
      <Modal.Trigger asChild>
        <BaseRecommendationCard
          onPress={() => {
            posthog?.capture('recommendation_savingsrate_click');
          }}
          title={t('RECOMMENDATION.SAVINGSRATE.TITLE')}
          description={t('RECOMMENDATION.SAVINGSRATE.DESCRIPTION')}
          iconPath={require('@/assets/images/recommendation-savingsrate.png')}
        />
      </Modal.Trigger>
      <Modal.Content side>
        <YStack gap="$5">
          <YStack gap="$3">
            <Heading4 variant="medium">{t('RECOMMENDATION.SAVINGSRATE.MODAL.TITLE')}</Heading4>
            <Paragraph2 color="$neutral400">
              {t('RECOMMENDATION.SAVINGSRATE.MODAL.DESCRIPTION')}
            </Paragraph2>
          </YStack>

          <YStack gap="$3">
            <RecommendationModalBulletPoint
              iconName="savings-wealthbuilding"
              title={t('RECOMMENDATION.SAVINGSRATE.MODAL.POINT_1.TITLE')}
              description={t('RECOMMENDATION.SAVINGSRATE.MODAL.POINT_1.DESCRIPTION')}
            />

            <RecommendationModalBulletPoint
              iconName="savings-retirement"
              title={t('RECOMMENDATION.SAVINGSRATE.MODAL.POINT_2.TITLE')}
              description={t('RECOMMENDATION.SAVINGSRATE.MODAL.POINT_2.DESCRIPTION')}
            />
          </YStack>
          <Button
            isLoading={customers?.items[0].id === undefined}
            onPress={() => {
              ref.current?.open(false);
              if (customers?.items[0].id !== undefined) {
                posthog?.capture('recommendation_savingsrate_button_click');
                router.navigate({
                  pathname: '/overview/[customerId]',
                  params: {customerId: customers?.items[0].id, triggerSavingsRateChange: 'true'},
                });
              }
            }}
          >
            {t('RECOMMENDATION.SAVINGSRATE.MODAL.CTA')}
          </Button>
        </YStack>
      </Modal.Content>
    </Modal>
  );
}
