import {useDispatch, useSelector} from 'react-redux';
import {
  MigrationManifest,
  createMigrate,
  persistReducer,
  persistStore,
  type PersistConfig,
} from 'redux-persist';

import {configureStore} from '@reduxjs/toolkit';

import devToolsEnhancer from 'redux-devtools-expo-dev-plugin';
import {persistedStorage} from './persistedMMKVStorage';
import {baseApi} from './queries/baseApi';
import {validationApi} from './queries/validationApi';
import {RootState, rootReducers} from './reducers';

const migrations = {
  3: (state: RootState) => {
    // migration clear out device state
    return {
      ...state,
      app: {
        ...{
          theme: 'system',
          discreteMode: false,
          language: 'de',
          biometricEnabled: true,
        },
        ...state.app,
      },
    };
  },
  4: (state: RootState) => {
    // migration clear out device state
    return {
      ...state,
      app: {
        ...state.app,
        biometricEnabled: true,
      },
    };
  },
};

const persistConfig: PersistConfig<RootState> = {
  key: 'root',
  version: 4,
  storage: persistedStorage,
  blacklist: [baseApi.reducerPath, validationApi.reducerPath],
  migrate: createMigrate(migrations as unknown as MigrationManifest, {debug: false}),
};

const persistedReducer = persistReducer(persistConfig, rootReducers);

export const store = configureStore({
  reducer: persistedReducer,
  devTools: false,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
    }).concat(baseApi.middleware, validationApi.middleware),
  enhancers: getDefaultEnhancers =>
    __DEV__ ? getDefaultEnhancers().concat(devToolsEnhancer()) : getDefaultEnhancers(),
});

type AppDispatch = typeof store.dispatch;

export const useAppDispatch = useDispatch.withTypes<AppDispatch>();
export const useAppSelector = useSelector.withTypes<RootState>();

export const persistor = persistStore(store);
