import {yupResolver} from '@hookform/resolvers/yup';
import {useTranslate} from '@tolgee/react';
import {useEffect, useMemo, useRef} from 'react';
import {useForm} from 'react-hook-form';
import {YStack} from 'tamagui';

import {Button} from '@/components/buttons/Button';
import {FormAmountInput} from '@/components/inputs/form/FormAmountInput';
import {Skeleton} from '@/components/views/Skeleton';
import {DepositOrderSchemaType, useValidations} from '@/hooks/useValidations';
import {DepositMethodAccordion} from '@/modules/orders/components/DepositMethod/DepositMethodAccordion';
import {WarningBox} from '@/modules/orders/components/WarningBox';
import {useGetSpecificOrderConstraint} from '@/modules/orders/hooks/useGetSpecificOrderConstraint';
import {usePostHog} from '@/providers/posthog/usePostHog';
import {useGetTransactionsQuery} from '@/store/queries/apeironApi';
import {useGetCustomerAccountQuery} from '@/store/queries/customersApi';
import {Product} from '@/types/fixedTypes/customers.v2/Product';
import {skipToken} from '@reduxjs/toolkit/query';

type DepositFormProps = {
  customerId: string;
  onSubmit: (data: DepositOrderSchemaType) => void;
  product?: Product;
};

export function DepositForm({customerId, onSubmit, product}: DepositFormProps) {
  const {t} = useTranslate();
  const {depositOrderSchema} = useValidations();
  const {data: customer, isFetching} = useGetCustomerAccountQuery(customerId);
  const firstRender = useRef(true);
  const posthog = usePostHog();
  const {data: previousDebitOrders, isFetching: previousDebitTransactionsFetching} =
    useGetTransactionsQuery(
      customerId && customer?.custodian === 'DAB'
        ? {customerId, category: 'DIRECT_DEPOSIT', limit: 20, offset: 0}
        : skipToken
    );

  const previousDebitOrdersFiltered = useMemo(() => {
    if (
      previousDebitTransactionsFetching ||
      previousDebitOrders === undefined ||
      previousDebitOrders.items?.length === 0
    ) {
      return [];
    }
    const lastDate = new Date(Date.now() - 1000 * 60 * 60 * 24 * 7 * 8);
    return previousDebitOrders.items.filter(item => new Date(item.createdAt) > lastDate);
  }, [previousDebitOrders, previousDebitTransactionsFetching]);

  const {constraint: {canBeCreated, firstViolationLabel} = {}, loading} =
    useGetSpecificOrderConstraint(
      customerId,
      product === 'THEME_ACCOUNT' ? 'THEME_DEPOSIT' : 'DEPOSIT'
    );

  const {handleSubmit, control, watch, trigger} = useForm({
    resolver: yupResolver(depositOrderSchema),
    mode: 'onChange',
    context: {
      custodian: customer?.custodian,
      previousDebitOrders: previousDebitOrdersFiltered,
    },
    defaultValues: {depositMethod: 'direct'},
  });

  const depositMethod = watch('depositMethod');

  useEffect(() => {
    posthog?.capture('deposit_method_selected', {depositMethod});
  }, [depositMethod, posthog]);

  useEffect(() => {
    if (!firstRender.current) {
      trigger('deposit');
    }
    firstRender.current = false;
  }, [depositMethod, trigger]);

  return (
    <YStack gap="$4">
      <FormAmountInput
        control={control}
        name="deposit"
        label={t('ORDER_SUMMARY.DEPOSIT.AMOUNT-TITLE')}
        bordered
      />

      {isFetching && (
        <>
          <Skeleton width="100%" height={100} />
          <Skeleton width="100%" height={100} />
          <Skeleton width="100%" height={100} />
        </>
      )}
      {!isFetching && customer?.product !== 'THEME_ACCOUNT' && (
        <DepositMethodAccordion control={control} name="depositMethod" customerId={customerId} />
      )}
      <WarningBox text={firstViolationLabel} show={!loading && !canBeCreated} />
      <Button
        disabled={!canBeCreated || depositMethod === 'wire'}
        onPress={handleSubmit(onSubmit, data => {
          posthog?.capture('form_submit_failed', data);
        })}
      >
        {t('CONTINUE')}
      </Button>
    </YStack>
  );
}
