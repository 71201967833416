import React from 'react';

import SvgFile from '@/components/icons/SvgFile';
import {Label3} from '@/components/texts/Label';
import {useTranslate} from '@tolgee/react';
import {XStack} from 'tamagui';

export const AddNumber = ({onPress}: {onPress?: () => void}) => {
  const {t} = useTranslate();

  // TODO: use common style for text buttons like this
  return (
    <XStack
      alignSelf="flex-end"
      onPress={onPress}
      justifyContent="flex-end"
      alignItems="center"
      cursor="pointer"
      userSelect="none"
      hoverStyle={{opacity: 0.75}}
    >
      <SvgFile name="plus" color="$primary" size={18} />
      <Label3 color="$primary">{t('MFA.ADD_NUMBER')}</Label3>
    </XStack>
  );
};
