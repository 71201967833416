import {Badge} from '@/components/buttons/Badge';
import {Button} from '@/components/buttons/Button';
import {Card} from '@/components/cards/Card';
import SvgFile from '@/components/icons/SvgFile';
import {Label3} from '@/components/texts/Label';
import {Skeleton} from '@/components/views/Skeleton';
import {MFAModal} from '@/modules/mfa/components/MFAModal';
import {PhoneNumbersCard} from '@/modules/mfa/components/PhoneNumbersCard';
import {useMfaAvailability} from '@/modules/mfa/hooks/useMfaAvailability';
import {useGetMfaMethodsQuery} from '@/store/queries/authApi';
import {MFAActionType} from '@/types/mfaAction';
import {useTranslate} from '@tolgee/react';
import {useMemo, useState} from 'react';
import {XStack, YStack, useMedia} from 'tamagui';

export const MFAStatusOverview = () => {
  const {t} = useTranslate();
  const [action, setAction] = useState<MFAActionType | null>(null);
  const {data: methods, isFetching} = useGetMfaMethodsQuery();
  const {isSetup, isMFAUsable} = useMfaAvailability();
  const media = useMedia();

  const mfaStatus = useMemo(() => {
    let badge = null;
    if (!isFetching) {
      if (isMFAUsable) {
        badge = (
          <Badge backgroundColor="$greenTransparent">
            <Label3 color="$green" variant="medium">
              {t('MFA.ENABLED')}
            </Label3>
          </Badge>
        );
      } else if (isSetup) {
        badge = (
          <Badge backgroundColor="$yellowTransparent">
            <Label3 color="$yellowDark" variant="medium">
              {t('MFA.PENDING')}
            </Label3>
          </Badge>
        );
      } else {
        badge = (
          <Badge backgroundColor="$redTransparent">
            <Label3 color="$red" variant="medium">
              {t('MFA.DISABLED')}
            </Label3>
          </Badge>
        );
      }
    }
    return (
      <XStack alignItems="center" justifyContent="space-between">
        <Skeleton width={75}>{!isFetching && <Label3>{t('MFA.STATUS')}</Label3>}</Skeleton>
        <Skeleton width={100}>{badge}</Skeleton>
      </XStack>
    );
  }, [isFetching, isMFAUsable, isSetup, t]);

  return (
    <>
      <YStack gap="$4">
        <Card headerText={media.gtSm ? t('PROFILE.SECURITY.MFA') : undefined} gap="$4">
          <XStack
            justifyContent="center"
            alignItems="center"
            gap="$2"
            backgroundColor="$primaryTransparent"
            borderRadius="$1"
            padding="$5"
          >
            <SvgFile name="info" color="$primary" size={18} />
            <Label3 variant="medium" color="$primary">
              {t('MFA.MFA_DESCRIPTION')}
            </Label3>
          </XStack>

          {mfaStatus}

          {!isFetching && (!methods || methods.length === 0) && (
            <Button onPress={() => setAction({name: 'initial'})}>
              {t('MFA.ENABLE_SECOND_FACTOR')}
            </Button>
          )}
        </Card>
        {methods && methods.length > 0 && (
          <PhoneNumbersCard
            methods={methods}
            loading={isFetching}
            onAdd={id => {
              setAction({name: 'additional'});
            }}
            onRemove={id => {
              setAction({name: 'deletion', id});
            }}
          />
        )}
      </YStack>
      {action && <MFAModal action={action} />}
    </>
  );
};
