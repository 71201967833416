import {useMfaAvailability} from '@/modules/mfa/hooks/useMfaAvailability';
import {BaseRecommendationCard} from '@/modules/recommendations/components/BaseRecommendationCard';
import {usePostHog} from '@/providers/posthog/usePostHog';
import {useTranslate} from '@tolgee/react';
import {useRouter} from 'expo-router';

export function MfaRecommendationCard() {
  const {t} = useTranslate();
  const router = useRouter();
  const {isSetup, isFetching} = useMfaAvailability();
  const posthog = usePostHog();

  if (isFetching || isSetup) {
    return null;
  }

  return (
    <BaseRecommendationCard
      title={t('RECOMMENDATION.MFA.TITLE')}
      description={t('RECOMMENDATION.MFA.DESCRIPTION')}
      onPress={() => {
        posthog?.capture('recommendation_mfa_click');
        router.navigate('/profile/security');
      }}
      iconPath={require('@/assets/images/recommendation-mfa.png')}
    />
  );
}
