import {Button} from '@/components/buttons/Button';
import {Card} from '@/components/cards/Card';
import {FormTextInput} from '@/components/inputs/form/FormTextInput';
import useRefdataLocalizable from '@/hooks/useRefdataLocalizable';
import {useValidations} from '@/hooks/useValidations';
import {MFAModal} from '@/modules/mfa/components/MFAModal';
import {usePostHog} from '@/providers/posthog/usePostHog';
import {useChangePasswordMutation} from '@/store/queries/authApi';
import {useViolationsQuery} from '@/store/queries/referenceApi';
import {MFAActionType} from '@/types/mfaAction';
import {yupResolver} from '@hookform/resolvers/yup';
import {captureException} from '@sentry/react-native';
import {useTranslate} from '@tolgee/react';
import {toast} from 'burnt';
import {useCallback, useState} from 'react';
import {useForm} from 'react-hook-form';
import {Platform, TextInput} from 'react-native';
import {View, XStack, useMedia} from 'tamagui';

export const ChangePassword = () => {
  const {t} = useTranslate();
  const media = useMedia();
  const [changePasswordTrigger] = useChangePasswordMutation();
  const {changePasswordFormSchema} = useValidations();
  const {data: violations} = useViolationsQuery();
  const {getByCode} = useRefdataLocalizable();
  const {handleSubmit, control, reset, getValues} = useForm({
    resolver: yupResolver(changePasswordFormSchema),
    mode: 'onChange',
  });
  const posthog = usePostHog();
  const [action, setAction] = useState<MFAActionType | null>(null);

  const cancelPasswordChange = useCallback(() => {
    reset();
  }, [reset]);

  const onPasswordChangeSuccess = useCallback(() => {
    toast({
      preset: 'done',
      title: t('PROFILE.SECURITY.CHANGE-PASSWORD.SUCCESS'),
    });
  }, [t]);

  const onPasswordChangeFailure = useCallback(
    (violationCode: string | undefined) => {
      let violationMessage;
      if (violationCode !== undefined) {
        violationMessage = getByCode(violations, violationCode)?.label;
      }

      toast({
        preset: 'error',
        title: violationMessage ? violationMessage : t('SNACKBAR.SOMETHING_WENT_WRONG'),
      });
    },
    [getByCode, t, violations]
  );

  const onSuccessMFA = useCallback(async () => {
    const oldPassword = getValues('oldPassword');
    const newPassword = getValues('newPassword');

    if (!oldPassword || !newPassword) return;

    try {
      await changePasswordTrigger({oldPassword, newPassword}).unwrap();
      onPasswordChangeSuccess();
    } catch (error: any) {
      onPasswordChangeFailure(error?.data?.violations?.[0]?.code);
      captureException(error);
    }
  }, [changePasswordTrigger, getValues, onPasswordChangeFailure, onPasswordChangeSuccess]);

  const onSubmit = useCallback(() => {
    setAction({
      name: 'one-time-code',
      onSuccess: onSuccessMFA,
    });
  }, [onSuccessMFA]);

  return (
    <View>
      <Card headerText={media.gtSm ? t('PROFILE.SECURITY.CHANGE-PASSWORD') : undefined} gap="$7">
        <View flexDirection={media.sm ? 'column' : 'row'} gap="$4">
          <FormTextInput
            control={control}
            label={t('PROFILE.SECURITY.CHANGE-PASSWORD.OLD')}
            name="oldPassword"
            bordered
            textInputProps={{secureTextEntry: true, textContentType: 'password'}}
          />
          {/* Workaround for iOS strong password prompt */}
          {Platform.OS === 'ios' && <TextInput style={{height: 0.1, width: 0.1}} tabIndex={-1} />}
          <FormTextInput
            control={control}
            label={t('PROFILE.SECURITY.CHANGE-PASSWORD.NEW')}
            name="newPassword"
            bordered
            textInputProps={{secureTextEntry: true, textContentType: 'newPassword'}}
          />
        </View>
        <XStack gap="$7">
          <Button
            onPress={handleSubmit(onSubmit, data => {
              posthog?.capture('form_submit_failed', data);
            })}
          >
            {t('NAVIGATION.PROFILE.TAXES.BUTTON.SAVE-CHANGES')}
          </Button>
          <Button secondary onPress={cancelPasswordChange}>
            {t('NAVIGATION.PROFILE.TAXES.BUTTON.CANCEL')}
          </Button>
        </XStack>
      </Card>
      <MFAModal action={action} />
    </View>
  );
};
