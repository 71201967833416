import {Card} from '@/components/cards/Card';
import SvgFile from '@/components/icons/SvgFile';
import {Heading5} from '@/components/texts/Heading';
import {Paragraph2} from '@/components/texts/Paragraph';
import {GradientCard} from '@/modules/recommendations/components/GradientCard';
import {Image, ImageSource} from 'expo-image';
import {forwardRef, useMemo} from 'react';
import {GetProps, View, XStack, YStack} from 'tamagui';

type BaseRecommendationCardProps = {
  title: string;
  description: string;
  onPress?: () => void;
  iconPath: ImageSource;
  highlighted?: boolean;
  containerStyle?: GetProps<typeof Card>;
};

export const BaseRecommendationCard = forwardRef<any, BaseRecommendationCardProps>(
  function BaseRecommendationCard(
    {title, description, onPress, iconPath, highlighted, containerStyle},
    ref
  ) {
    const content = useMemo(() => {
      return (
        <YStack
          ref={ref}
          cursor="pointer"
          hoverStyle={{opacity: 0.8}}
          onPress={onPress}
          justifyContent="space-between"
          $platform-web={{height: '100%'}}
          gap="$2"
        >
          <YStack gap="$3">
            <Heading5
              variant="medium"
              color={highlighted ? '$white' : '$neutral900'}
              maxWidth={175}
            >
              {title}
            </Heading5>
            <Paragraph2 color={highlighted ? '$white' : '$neutral400'}>{description}</Paragraph2>
          </YStack>
          <XStack alignItems="flex-end" justifyContent="space-between" userSelect="none">
            <View
              backgroundColor={highlighted ? '$white10' : '$neutralBG'}
              padding="$2"
              borderRadius="$0.25"
            >
              <SvgFile name="chevron-right" color={highlighted ? '$white' : '$primary'} />
            </View>

            <Image source={iconPath} style={{height: 54, width: 54}} contentFit="contain" />
          </XStack>
        </YStack>
      );
    }, [description, highlighted, iconPath, onPress, ref, title]);

    if (highlighted)
      return (
        <GradientCard
          containerStyle={{
            padding: '$6',
            $sm: {
              padding: '$4',
            },
            borderRadius: '$1',
            ...containerStyle,
          }}
        >
          {content}
        </GradientCard>
      );
    else
      return (
        <Card borderRadius="$1" $sm={{width: '100%'}} {...containerStyle}>
          {content}
        </Card>
      );
  }
);
