import {BaseRecommendationCard} from '@/modules/recommendations/components/BaseRecommendationCard';
import {usePostHog} from '@/providers/posthog/usePostHog';
import {useTranslate} from '@tolgee/react';
import {useRouter} from 'expo-router';

export function FriendsRecommendationCard() {
  const {t} = useTranslate();
  const router = useRouter();
  const posthog = usePostHog();

  return (
    <BaseRecommendationCard
      title={t('RECOMMENDATION.FRIENDS.TITLE')}
      description={t('RECOMMENDATION.FRIENDS.DESCRIPTION')}
      onPress={() => {
        posthog?.capture('recommendation_friends_click');
        router.navigate('/(user)/friends');
      }}
      iconPath={require('@/assets/images/recommendation-friends.png')}
    />
  );
}
