export const UMLAUTS_REGEXP = /[ÄÖÜßäöüẞ]/;

export const COUNTRY_ID_MATCH: {[code: string]: RegExp} = {
  BE: /^[0-9]{2}([0][1-9]|[1][012])([0][1-9]|[12][0-9]|[3][01])[0-9]{5}$/,
  BG: /^[0-9]{2}([0][1-9]|[1][012]|[4][1-9]|[5][012])([0][1-9]|[12][0-9]|[3][01])[0-9]{4}$/,
  CY: /^(([E][0-9]{6})\b|([K][0-9]{8}))$/,
  CZ: /^[0-9]{2,6}-?[0-9]{2,10}\/[0-9]{4}$/,
  DK: /^([0][1-9]|[12][0-9]|[3][01])([0][1-9]|[1][012])[0-9]{2}[0-9]{4}$/,
  EE: /^[1-6][0-9]{2}([0][1-9]|[1][012])([0][1-9]|[12][0-9]|[3][01])[0-9]{4}$/,
  ES: /^[LK0-9]{1}[0-9]{7}[A-HJ-NP-TV-Z]{1}$/,
  FI: /^([0][1-9]|[1][0-9]|[2][0-9]|[3][01])([0][1-9]|[1][012])[0-9]{2}[A+-][0-9]{3}(?![GIOQZ])[A-Z0-9]$/,
  GB: /\b[A-Z0-9]{3,}\b/,
  GR: /^[0-9]{10}$/,
  HR: /^[0-9]{11}$/,
  IS: /^([0][1-9]|[1][0-9]|[2][0-9]|[3][01])([0][1-9]|[1][012])[0-9]{2}[0-9]{4}$/,
  IT: /^[A-Z]{6}[0-9LMNPQRSTUV]{2}[ABCDEHLMPRST]{1}[0-9LMNPQRSTUV]{2}[A-Z]{1}[0-9LMNPQRSTUV]{3}[A-Z]{1}$/,
  LI: /^[A-Z][0-9]{5}$/,
  LT: /^[1-6][0-9]{2}[0,1][0-9][0-9]{2}[0-9]{4}$/,
  LV: /^(0[1-9]|[12][0-9]|3[01])(0[1-9]|1[012])(\d{2})[-]?(0|1|2)(\d{4})$/,
  MT: /^[0-9]{7}[A-Z]$/,
  NL: /^[A-NP-Z]{2}[A-NP-Z0-9]{6}[0-9]$/,
  NO: /^([0][1-9]|[12][0-9]|[3][01])([0][1-9]|[1][012])[0-9]{7}$/,
  PL: /^[0-9]{11}$/,
  PT: /^[0-9]{9}$/,
  RO: /^[1-9]\d{2}(0[1-9]|1[0-2])(0[1-9]|[12]\d|3[01])(0[1-9]|[1-4]\d|5[0-2]|99)(00[1-9]|0[1-9]\d|[1-9]\d\d)\d$/,
  SE: /^((19)|(20))[0-9]{2}([0][1-9]|[1][012])([0][1-9]|[12][0-9]|[3][01])[0-9]{4}$/,
  SI: /^([0][1-9]|[12][0-9]|[3][01])([0][1-9]|[1][012])[0-9]{3}[1-9][0-9]{5}$/,
  SK: /^[0-9]{2,6}-?[0-9]{2,10}[0-9]{4}$/,
};

export const DEFAULT_ID_MATCH = /\b[A-Z0-9]{3,}\b/;

export const TIN_MATCH: {[code: string]: RegExp} = {
  AT: /^([0-9]{7})|([0-9]{9})$/,
  BE: /^[0-9]{11}$/,
  BG: /^[0-9]{10}$/,
  CY: /^[069][0-9]{7}[A-Z]$/,
  CZ: /^[0-9]{9,10}$/,
  DE: /^[0-9]{11}$/,
  DK: /^[0-9]{10}$/,
  EE: /^[0-9]{11}$/,
  ES: /^[0-9KLMXYZ][0-9]{7}[A-Z]$/,
  FI: /^[0-9]{6}[\+\-A][0-9]{3}[0-9A-Z]$/,
  FR: /^[0-9]{13}$/,
  GB: /^([A-Z]{2}[0-9]{6}[ABCD])|([0-9]{10})$/,
  GR: /^[0-9]{9}$/,
  HR: /^[0-9]{11}$/,
  HU: /^[0-9]{10}$/,
  IE: /^[0-9]{7}[A-Z]{1,2}$/,
  IT: /^[A-Z]{6}[0-9]{2}[A-Z][0-9]{2}[A-Z][0-9]{3}[A-Z]$/,
  LT: /^[0-9]{11}$/,
  LV: /^[0-9]{11}$/,
  LU: /^[0-9]{13}$/,
  MT: /^([0-9]{7}[ABGHLMPZ])|([0-9]{9})$/,
  NL: /^[0-9]{9}$/,
  PL: /^[0-9]{10,11}$/,
  PT: /^[0-9]{9}$/,
  RO: /^[0-9]{13}$/,
  SE: /^(19|20)[0-9]{2}(0[1-9]|1[0-2])(0[1-9]|[1-2][0-9]|3[0-1])[0-9]{4}$/,
  SI: /^[0-9]{8}$/,
  SK: /^[0-9]{10}$/,
};

export const COMMON_REGEXP = {
  zipCode: /^[a-z0-9 \-]+$/i,
  streetNumber: /^[A-Za-z0-9 ,-.()/]+$/,
  german: /^[a-zA-ZäöüßÄÖÜẞ0-9 .'-_=+,:;\\/\\\\]+$/,
  germanName:
    /^[A-Za-zÀÁÂÃÄÅÆÇĆČÈÉÊËÌÍÎÏÐĐÑÒÓÔÕÖØŠÙÚÛÜÝŽßẞàáâãäåæçćčđèéêëìíîïñòóôõöøšùúûüýÿž., '`´/()-]+$/,
  germanWithNumbers:
    /^[A-Za-z0-9ÀÁÂÃÄÅÆÇĆČÈÉÊËÌÍÎÏÐĐÑÒÓÔÕÖØŠÙÚÛÜÝŽßẞàáâãäåæçćčđèéêëìíîïñòóôõöøšùúûüýÿž., '`´/()-]+$/,
  date: /^[0-9]{4}\-[0-9]{2}\-[0-9]{2}$/,
  germanTin: /^[1-9]\d{10}$/,
};
