import {yupResolver} from '@hookform/resolvers/yup';
import {useTranslate} from '@tolgee/react';
import {
  PropsWithChildren,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';
import {useForm} from 'react-hook-form';
import {View, XStack, YStack, useTheme} from 'tamagui';

import SvgFile from '@/components/icons/SvgFile';
import {TimePeriodProjectionSelect} from '@/components/inputs/TimePeriodProjectionSelect';
import {FormAmountInput} from '@/components/inputs/form/FormAmountInput';
import {Area, Chart, Line} from '@/components/skia';
import {WebGlFallback} from '@/components/skia/WebGlFallback';
import {Heading3, Heading5} from '@/components/texts/Heading';
import {Label3} from '@/components/texts/Label';
import LocalizedNumbers from '@/components/texts/LocalizedNumbers';
import {Surface} from '@/components/views/Surface';
import useDebounce from '@/hooks/useDebounce';
import {useLocalizedMonths} from '@/hooks/useLocalizedMonths';
import {InvestmentProposalDepositSchemaType, useValidations} from '@/hooks/useValidations';
import {TipWithSideModal} from '@/modules/interview/components/TipWithSideModal';
import {InvestTipModalContent} from '@/modules/interview/components/planner/InvestTipModalContent';
import {VLInvestTipModalContent} from '@/modules/interview/components/planner/VLInvestTipModalContent';
import {useAppDispatch, useAppSelector} from '@/store';
import {usePerformanceProjectionsQuery} from '@/store/queries/referenceApi';
import {saveEmployer, saveInitialDepositAmount, savePlanner} from '@/store/reducers/onboarding';
import {ShortenedCustomerInformationResponse} from '@/types/api/customers.v2';
import {initialInvestmentStrategy} from '@/types/api/orders';
import {GetPerformanceProjectionsData} from '@/types/api/refdata.v2';
import {InvestmentStrategy} from '@/types/investmentStrategy';
import {Point} from '@/types/skia';

export type ProjectDevelopmentTabProps = {
  initialDeposit: number;
  initialMonthlySavings: number;
  product: ShortenedCustomerInformationResponse['product'];
  selectedStrategy: string;
  showInputs?: boolean;
};

export type RangeSelect = {
  label: string;
  value: number;
  filter: number;
  lttbSampleCount: number;
};

const VL_DEFAULT_PERIOD = 7;
const DEFAULT_PERIOD = 10;

export type ProjectDevelopmentTabHandle = {
  submit: () => Promise<void>;
};

export default forwardRef<
  ProjectDevelopmentTabHandle,
  PropsWithChildren<ProjectDevelopmentTabProps>
>(function ProjectDevelopmentTab(
  {initialDeposit, initialMonthlySavings, product, selectedStrategy, showInputs = true},
  ref
) {
  const {t} = useTranslate();
  const {investmentProposalDepositSchema} = useValidations();
  const {control, watch, formState} = useForm({
    resolver: yupResolver(investmentProposalDepositSchema),
    mode: 'onChange',
    context: {
      product,
    },
    defaultValues: {
      INITIAL_DEPOSIT: Number(initialDeposit),
      INITIAL_MONTHLY_SAVINGS: Number(initialMonthlySavings),
    },
  });
  const isVLProduct = useMemo(() => product === 'VL_ACCOUNT', [product]);

  const isSavingsAccount = useMemo(() => product === 'SAVINGS_ACCOUNT', [product]);

  const [years, setYears] = useState<number>(isVLProduct ? VL_DEFAULT_PERIOD : DEFAULT_PERIOD);

  const [request, setRequest] = useState<GetPerformanceProjectionsData>({
    initialDepositAmount: Number(initialDeposit),
    recurringDepositAmount: Number(initialMonthlySavings),
    investmentStrategy: selectedStrategy as initialInvestmentStrategy,
    recurringDepositType: 'MONTHLY',
    investmentPeriodMonths: years * 12,
    startDate: new Date().toISOString().slice(0, 10),
  });
  const onboardingInitialDeposit = useAppSelector(
    state => state.onboarding.personalDetails.initialDeposit
  );

  const dispatch = useAppDispatch();

  const theme = useTheme();
  const [good, setGood] = useState<Point[]>([]);
  const [expected, setExpected] = useState<Point[]>([]);
  const [paid, setPaid] = useState<Point[]>([]);
  const [bad, setBad] = useState<Point[]>([]);

  const {data: performanceProjections} = usePerformanceProjectionsQuery(request);
  const [lastDate, setLastDate] = useState<{date: Date; amount: number}>();

  const [inputValues, setInputValues] = useState<InvestmentProposalDepositSchemaType>({
    INITIAL_DEPOSIT: request.initialDepositAmount,
    INITIAL_MONTHLY_SAVINGS: request.recurringDepositAmount ?? 0,
  });

  const updateProjectionValues = useCallback(
    (values: InvestmentProposalDepositSchemaType) => {
      setRequest(req => {
        const newRequest = {...req};
        if (!isVLProduct) newRequest.initialDepositAmount = values.INITIAL_DEPOSIT ?? 0;
        newRequest.recurringDepositAmount = values.INITIAL_MONTHLY_SAVINGS;
        return newRequest;
      });
    },
    [isVLProduct]
  );

  const saveValuesToStore = useCallback(
    ({
      savingsRate,
      deposit,
      selectedStrategy,
    }: {
      savingsRate: number;
      deposit: number;
      selectedStrategy: InvestmentStrategy;
    }) => {
      if (isVLProduct) {
        dispatch(saveEmployer({savingsRate}));
      }
      dispatch(
        savePlanner({
          initialDepositAmount: deposit,
          initialRecurringDepositAmount: savingsRate,
          selectedStrategy,
        })
      );
      dispatch(saveInitialDepositAmount(deposit));
    },
    [dispatch, isVLProduct]
  );

  useImperativeHandle(
    ref,
    () => ({
      submit: async () => {
        saveValuesToStore({
          deposit: Number(inputValues.INITIAL_DEPOSIT),
          savingsRate: Number(inputValues.INITIAL_MONTHLY_SAVINGS),
          selectedStrategy: selectedStrategy as InvestmentStrategy,
        });
      },
    }),
    [inputValues, saveValuesToStore, selectedStrategy]
  );

  useDebounce(
    () => {
      if (formState.isValid) {
        updateProjectionValues(inputValues);
      }
    },
    500,
    [inputValues, isVLProduct, selectedStrategy]
  );

  useEffect(() => {
    saveValuesToStore({
      deposit: initialDeposit,
      selectedStrategy: selectedStrategy as initialInvestmentStrategy,
      savingsRate: initialMonthlySavings,
    });
  }, [initialDeposit, initialMonthlySavings, saveValuesToStore, selectedStrategy]);

  useEffect(() => {
    const subcription = watch(data => {
      setInputValues(data as InvestmentProposalDepositSchemaType);
    });
    return () => subcription.unsubscribe();
  }, [formState, watch]);

  useEffect(() => {
    setRequest(req => {
      const newRequest = {...req};
      newRequest.investmentPeriodMonths = years * 12;
      return newRequest;
    });
  }, [years]);

  useEffect(() => {
    if (!performanceProjections || performanceProjections.length === 0) return;

    const good: Point[] = [];
    const expected: Point[] = [];
    const paid: Point[] = [];
    const bad: Point[] = [];

    for (const data of performanceProjections) {
      const dateTimestamp = Math.floor(new Date(data.date).getTime() / 1000);

      good.push({x: dateTimestamp, y: data.projectedP95});
      expected.push({x: dateTimestamp, y: data.medianGrowth});
      paid.push({x: dateTimestamp, y: data.deposited});
      bad.push({x: dateTimestamp, y: data.projectedP5});
    }

    setGood(good);
    setExpected(expected);
    setPaid(paid);
    setBad(bad.reverse());

    setLastDate({
      date: new Date(performanceProjections[performanceProjections.length - 1].date),
      amount: performanceProjections[performanceProjections.length - 1].medianGrowth,
    });
  }, [performanceProjections]);

  const months = useLocalizedMonths();

  const displayedDate = useMemo(() => {
    if (!lastDate?.date) return;
    return t('PROJECTED-DEVELOPMENT.POSSIBLE-RETURN', {
      date: `${months[lastDate?.date.getMonth()].name} ${lastDate?.date.getFullYear()}`,
    });
  }, [lastDate?.date, months, t]);

  useDebounce(
    () => {
      setRequest(req => ({
        ...req,
        investmentStrategy: selectedStrategy as initialInvestmentStrategy,
      }));
    },
    150,
    [selectedStrategy]
  );

  return (
    <YStack flex={1}>
      <Surface>
        <YStack padding="$4" gap="$5">
          <Heading5 variant="medium">{t('PROJECTED-DEVELOPMENT.PROJECT-DEVELOPMENT')}</Heading5>
          <YStack gap="$2">
            {showInputs && (
              <XStack gap="$5" width="100%">
                {product !== 'VL_ACCOUNT' && (
                  <View flex={1}>
                    <FormAmountInput
                      label={t('PROJECTED-DEVELOPMENT.INITIAL-DEPOSIT')}
                      control={control}
                      name="INITIAL_DEPOSIT"
                      bordered
                    />
                  </View>
                )}
                <View flex={product === 'VL_ACCOUNT' ? 0.45 : 1}>
                  <FormAmountInput
                    label={t('PROJECTED-DEVELOPMENT.MONTLY-DEPOSIT')}
                    control={control}
                    name="INITIAL_MONTHLY_SAVINGS"
                    bordered
                    decimal={product === 'VL_ACCOUNT'}
                  />
                </View>
              </XStack>
            )}
            <View alignSelf="flex-start">
              <TipWithSideModal
                tipTitle={
                  product === 'VL_ACCOUNT'
                    ? t('INVESTMENT_PLANNER.VL_SAVINGS_CALC.TITLE')
                    : t('INVESTMENT_PLANNER.HOW_MUCH_I_SHALL_INVEST.TEXT')
                }
                tipContent={
                  product === 'VL_ACCOUNT' ? <VLInvestTipModalContent /> : <InvestTipModalContent />
                }
              />
            </View>
          </YStack>

          <XStack justifyContent="space-between" minWidth="45%" gap="$2" flexWrap="wrap">
            <YStack gap="$1">
              <Label3 color="$neutral500">{displayedDate}</Label3>
              <LocalizedNumbers
                value={lastDate?.amount}
                numberStyle={{color: '$primary', variant: 'medium'}}
                textComponent={Heading3}
                precision="integer"
              />
            </YStack>
            <TimePeriodProjectionSelect
              onIndexChange={setYears}
              containerStyle={{
                alignSelf: 'flex-start',
              }}
            />
          </XStack>
        </YStack>

        <View width="100%" height={240}>
          <Chart
            removeSelectActionOnEnd
            overlapYAxes
            removeHorizontalOffset
            webGlFallback={<WebGlFallback />}
            theme={theme}
          >
            {!isSavingsAccount && (
              <Area color={theme.primary.val} upperBound={good} lowerBound={bad} opacity={0.1} />
            )}
            <Line
              points={expected}
              color={theme.primary.val}
              activePoint={{
                show: true,
                size: 8,
              }}
            />
            <Line
              points={paid}
              color={theme.greenLight.val}
              activePoint={{
                show: true,
                size: 8,
              }}
            />
          </Chart>
        </View>
        <XStack gap="$8" $sm={{gap: '$4'}} padding="$4" marginTop="$4" flexWrap="wrap">
          <XStack gap="$1" alignItems="center">
            <SvgFile size={20} name="circle" color="$primary" />
            <Label3 color="$primary">{t('PROJECTED-DEVELOPMENT.LEGEND.EXPECTED')}</Label3>
          </XStack>
          <XStack gap="$1" alignItems="center">
            <SvgFile size={20} name="circle" color="$emerald" />
            <Label3 color="$emerald">{t('PROJECTED-DEVELOPMENT.LEGEND.PAID')}</Label3>
          </XStack>
          {!isSavingsAccount && (
            <XStack gap="$1" alignItems="center">
              <SvgFile size={20} name="circle" color={theme.primary.val} opacity={0.3} />
              <Label3 color="$neutral500">{t('PROJECTED-DEVELOPMENT.LEGEND.GOOD')}</Label3>
            </XStack>
          )}
        </XStack>
      </Surface>
    </YStack>
  );
});
