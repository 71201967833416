import {Button} from '@/components/buttons/Button';
import {Card} from '@/components/cards/Card';
import {FormCountrySelect} from '@/components/inputs/form/FormCountrySelect';
import {FormTextInput} from '@/components/inputs/form/FormTextInput';
import useRefdataLocalizable from '@/hooks/useRefdataLocalizable';
import {AddressDetailsSchemaType, useValidations} from '@/hooks/useValidations';
import {MFARequiredNote} from '@/modules/mfa/components/MFARequiredNote';
import {useMfaAvailability} from '@/modules/mfa/hooks/useMfaAvailability';
import {usePostHog} from '@/providers/posthog/usePostHog';
import {useCountriesQuery} from '@/store/queries/referenceApi';
import {useGetSelfUserQuery} from '@/store/queries/usersApi';
import {yupResolver} from '@hookform/resolvers/yup';
import {useTranslate} from '@tolgee/react';
import {useRouter} from 'expo-router';
import {useCallback} from 'react';
import {useForm} from 'react-hook-form';
import {XStack} from 'tamagui';

export function AddressChangeCard() {
  const {t} = useTranslate();
  const {addressDetailsSchema} = useValidations();
  const {data: user} = useGetSelfUserQuery();
  const router = useRouter();
  const {data: countries} = useCountriesQuery();
  const {getByCode} = useRefdataLocalizable();
  const {handleSubmit, control} = useForm({
    resolver: yupResolver(addressDetailsSchema),
    mode: 'onChange',
  });
  const {isMFAUsable} = useMfaAvailability();
  const posthog = usePostHog();

  const onSubmit = useCallback(
    (data: AddressDetailsSchemaType) => {
      router.navigate({pathname: '/profile/change-address-proof', params: data});
    },
    [router]
  );

  return (
    <Card headerText={t('PERSONAL_DETAILS.ADDRESS_CHANGE.TITLE')} gap="$6">
      <MFARequiredNote />
      <XStack gap="$4" flexWrap="wrap">
        <FormTextInput
          bordered
          control={control}
          name="street"
          label={t('PERSONAL_DETAILS.ADDRESS_CHANGE.STREET')}
          textInputProps={{
            placeholder: user?.address?.street,
            disabled: !isMFAUsable,
          }}
          smFullWidth
        />
        <FormTextInput
          bordered
          control={control}
          name="streetNumber"
          label={t('PERSONAL_DETAILS.ADDRESS_CHANGE.STREET_NUMBER')}
          textInputProps={{
            placeholder: user?.address?.streetNumber,
            disabled: !isMFAUsable,
          }}
          smFullWidth
        />
      </XStack>

      <XStack gap="$4" flexWrap="wrap">
        <FormTextInput
          bordered
          control={control}
          name="postCode"
          label={t('PERSONAL_DETAILS.ADDRESS_CHANGE.POST_CODE')}
          textInputProps={{
            placeholder: user?.address?.postCode,
            disabled: !isMFAUsable,
          }}
          smFullWidth
        />
        <FormTextInput
          bordered
          control={control}
          name="city"
          label={t('PERSONAL_DETAILS.ADDRESS_CHANGE.CITY')}
          textInputProps={{
            placeholder: user?.address?.city,
            disabled: !isMFAUsable,
          }}
          smFullWidth
        />
        <FormCountrySelect
          bordered
          control={control}
          name="countryCode"
          label={t('PERSONAL_DETAILS.ADDRESS_CHANGE.COUNTRY')}
          disabled={!isMFAUsable}
          smFullWidth
          placeholder={
            user?.address?.countryCode && getByCode(countries, user?.address?.countryCode)?.label
          }
        />
      </XStack>
      <Button
        disabled={!isMFAUsable && t('SNACKBAR.MFA.REQUIRED')}
        onPress={handleSubmit(onSubmit, data => {
          posthog?.capture('form_submit_failed', data);
        })}
      >
        {t('PERSONAL_DETAILS.ADDRESS_CHANGE.SAVE')}
      </Button>
    </Card>
  );
}
