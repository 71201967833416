import {Button} from '@/components/buttons/Button';
import {Card} from '@/components/cards/Card';
import {FormTextInput} from '@/components/inputs/form/FormTextInput';
import {DEFAULT_COUNTRY} from '@/constants/countries';
import {useValidations} from '@/hooks/useValidations';
import {usePostHog} from '@/providers/posthog/usePostHog';
import {useGetSelfUserQuery, useUpdateUserMutation} from '@/store/queries/usersApi';
import {yupResolver} from '@hookform/resolvers/yup';
import {useTranslate} from '@tolgee/react';
import {toast} from 'burnt';
import {useCallback, useEffect, useMemo} from 'react';
import {useForm} from 'react-hook-form';
import {XStack} from 'tamagui';

export const GermanTaxIDCard = () => {
  const {t} = useTranslate();
  const {data: user} = useGetSelfUserQuery();
  const {updateGermanTaxIdentificationSchema} = useValidations();
  const posthog = usePostHog();

  const {control, handleSubmit, reset} = useForm({
    resolver: yupResolver(updateGermanTaxIdentificationSchema),
    defaultValues: {newTin: ''},
    mode: 'onChange',
  });
  const userTins = useMemo(() => user?.taxIdentification, [user]);
  const defaultCountryTin = useMemo(
    () =>
      userTins?.find(tax => tax.country.toLowerCase() === DEFAULT_COUNTRY.code.toLowerCase())
        ?.number,
    [userTins]
  );
  const [updateUser] = useUpdateUserMutation();

  useEffect(() => {
    reset({newTin: defaultCountryTin});
  }, [reset, defaultCountryTin]);

  const saveChanges = useCallback(
    async (data: any) => {
      const newGermanTin = data.newTin;
      if (defaultCountryTin === newGermanTin) {
        return;
      }
      const newTaxes = [...(userTins ?? [])];
      const newGermanTaxId = {
        country: DEFAULT_COUNTRY.code,
        number: newGermanTin,
      };
      const germanTaxIdIndex = newTaxes.findIndex(
        tax => tax.country.toLowerCase() === DEFAULT_COUNTRY.code.toLowerCase()
      );
      if (germanTaxIdIndex === -1) {
        newTaxes.push(newGermanTaxId);
      } else {
        newTaxes[germanTaxIdIndex] = newGermanTaxId;
      }
      if (user) {
        const res = await updateUser({currentUser: user, changes: {taxIdentification: newTaxes}});
        if (!res.error)
          toast({
            preset: 'done',
            title: t('TAXES.SUCESSFULLY_UPDATED'),
          });
        else
          toast({
            preset: 'error',
            title: t('SNACKBAR.ERROR_TITLE'),
          });
      }
    },
    [defaultCountryTin, userTins, user, updateUser, t]
  );

  const cancel = () => {
    reset({newTin: defaultCountryTin});
  };

  return (
    <Card headerText={t('NAVIGATION.PROFILE.YOUR-TAX')} gap="$3" padding="$5">
      <XStack width={300}>
        <FormTextInput
          control={control}
          name="newTin"
          label={t('TAXES.TAX-SETTINGS')}
          bordered
          textInputProps={{inputMode: 'numeric'}}
        />
      </XStack>
      <XStack gap="$3">
        <Button
          onPress={handleSubmit(saveChanges, data => {
            posthog?.capture('form_submit_failed', data);
          })}
        >
          {t('NAVIGATION.PROFILE.TAXES.BUTTON.SAVE-CHANGES')}
        </Button>
        <Button secondary onPress={cancel}>
          {t('NAVIGATION.PROFILE.TAXES.BUTTON.CANCEL')}
        </Button>
      </XStack>
    </Card>
  );
};
