import {FriendsRecommendationCard} from '@/modules/recommendations/components/FriendsRecommendationCard';
import {MfaRecommendationCard} from '@/modules/recommendations/components/MfaRecommendationCard';
import {SavingsrateRecommendationCard} from '@/modules/recommendations/components/SavingsrateRecommendationCard';
import {TopzinsRecommendationCard} from '@/modules/recommendations/components/TopzinsRecommendationCard';
import {View} from 'tamagui';

export function Recommendations() {
  return (
    <View gap="$4" flexDirection="row" flexWrap="wrap">
      <MfaRecommendationCard />
      <TopzinsRecommendationCard />
      <SavingsrateRecommendationCard />
      <FriendsRecommendationCard />
    </View>
  );
}
