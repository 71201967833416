import {Button} from '@/components/buttons/Button';
import {Modal} from '@/components/modal/Modal';
import {Heading4} from '@/components/texts/Heading';
import {Paragraph2} from '@/components/texts/Paragraph';
import {isAccountOrderExpired} from '@/helpers/isAccountOrderExpired';
import {BaseRecommendationCard} from '@/modules/recommendations/components/BaseRecommendationCard';
import {RecommendationModalBulletPoint} from '@/modules/recommendations/components/RecommendationModalBulletPoint';
import {usePostHog} from '@/providers/posthog/usePostHog';
import {useGetCustomerAccountsQuery} from '@/store/queries/customersApi';
import {useGetValidOrdersQuery} from '@/store/queries/ordersApi';
import {ModalRef} from '@/types/modal';
import {useTranslate} from '@tolgee/react';
import {useRouter} from 'expo-router';
import {useRef} from 'react';
import {YStack} from 'tamagui';

export function TopzinsRecommendationCard() {
  const {t} = useTranslate();
  const router = useRouter();
  const ref = useRef<ModalRef>(null);
  const {data: customers, isFetching: isCustomersFetching} = useGetCustomerAccountsQuery();
  const {data: orders, isFetching: isOrdersFetching} = useGetValidOrdersQuery();
  const posthog = usePostHog();

  if (
    (customers && customers.items.filter(c => c.product === 'SAVINGS_ACCOUNT').length > 0) ||
    (orders &&
      orders.items
        .filter(item => !isAccountOrderExpired(item))
        .filter(o => o.account.product === 'SAVINGS_ACCOUNT').length > 0)
  ) {
    return null;
  }

  if (isCustomersFetching || isOrdersFetching) return null;

  return (
    <Modal ref={ref}>
      <Modal.Trigger asChild>
        <BaseRecommendationCard
          onPress={() => {
            posthog?.capture('recommendation_topzins_click');
          }}
          title={t('RECOMMENDATION.TOPZINS.TITLE')}
          description={t('RECOMMENDATION.TOPZINS.DESCRIPTION')}
          iconPath={require('@/assets/images/recommendation-topzins.png')}
        />
      </Modal.Trigger>
      <Modal.Content side>
        <YStack gap="$5">
          <YStack gap="$3">
            <Heading4 variant="medium">{t('RECOMMENDATION.TOPZINS.MODAL.TITLE')}</Heading4>
            <Paragraph2 color="$neutral400">
              {t('RECOMMENDATION.TOPZINS.MODAL.DESCRIPTION')}
            </Paragraph2>
          </YStack>

          <YStack gap="$3">
            <RecommendationModalBulletPoint
              iconName="topzins-profit"
              title={t('RECOMMENDATION.TOPZINS.MODAL.POINT_1.TITLE')}
              description={t('RECOMMENDATION.TOPZINS.MODAL.POINT_1.DESCRIPTION')}
            />

            <RecommendationModalBulletPoint
              iconName="topzins-payment"
              title={t('RECOMMENDATION.TOPZINS.MODAL.POINT_2.TITLE')}
              description={t('RECOMMENDATION.TOPZINS.MODAL.POINT_2.DESCRIPTION')}
            />

            <RecommendationModalBulletPoint
              iconName="topzins-eu"
              title={t('RECOMMENDATION.TOPZINS.MODAL.POINT_3.TITLE')}
              description={t('RECOMMENDATION.TOPZINS.MODAL.POINT_3.DESCRIPTION')}
            />
          </YStack>
          <Button
            onPress={() => {
              // TODO: we should navigate to product directly
              // TODO: but for this we need to move logic from ProductChoiceList to separate hook
              // TODO: depending on interview etc. we navigate to different screens
              posthog?.capture('recommendation_topzins_button_click');
              router.navigate({
                pathname: '/interview/product-choice',
              });
              ref.current?.open(false);
            }}
          >
            {t('RECOMMENDATION.TOPZINS.MODAL.CTA')}
          </Button>
        </YStack>
      </Modal.Content>
    </Modal>
  );
}
