import {Button} from '@/components/buttons/Button';
import {FormPhonenumberInput} from '@/components/inputs/form/FormPhonenumberInput';
import {PhoneNumberSchemaType, useValidations} from '@/hooks/useValidations';
import {usePostHog} from '@/providers/posthog/usePostHog';
import {useGetSelfUserQuery, useUpdateUserMutation} from '@/store/queries/usersApi';
import {UserResponseV3} from '@/types/api/users';
import {yupResolver} from '@hookform/resolvers/yup';
import {useTranslate} from '@tolgee/react';
import {toast} from 'burnt';
import React, {useEffect} from 'react';
import {useForm} from 'react-hook-form';
import {XStack} from 'tamagui';

export function ChangePhoneNumberInput() {
  const {t} = useTranslate();
  const {phoneNumberSchema} = useValidations();
  const {data: user} = useGetSelfUserQuery();
  const [updateUser] = useUpdateUserMutation();
  const {control, handleSubmit, setValue} = useForm({
    resolver: yupResolver(phoneNumberSchema),
  });
  const posthog = usePostHog();

  const onValid = async (values: PhoneNumberSchemaType) => {
    let changes: Partial<UserResponseV3> | undefined;
    if (values.phoneNumber && !values.phoneNumber.includes('X')) {
      changes = {phoneNumber: values.phoneNumber.replace(/\s/g, '')};
    }
    if (user && changes) {
      const res = await updateUser({
        currentUser: user,
        changes,
      });
      if (!res.error)
        toast({
          preset: 'done',
          title: t('PERSONAL_DETAILS.PHONE_NUMBER.SUCESSFULLY_UPDATED'),
        });
      else
        toast({
          preset: 'error',
          title: t('SNACKBAR.ERROR_TITLE'),
        });
    }
  };

  useEffect(() => {
    setValue('phoneNumber', user?.phoneNumber);
  }, [setValue, user]);

  return (
    <XStack gap="$4" alignItems="flex-end">
      <FormPhonenumberInput
        control={control}
        name="phoneNumber"
        label={t('PERSONAL_DETAILS.ENTER_PHONE_NUMBER')}
        bordered
      />
      <Button
        marginBottom={3}
        onPress={handleSubmit(onValid, data => {
          posthog?.capture('form_submit_failed', data);
        })}
      >
        {t('SAVE')}
      </Button>
    </XStack>
  );
}
