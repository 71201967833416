import {ChangeEmail} from '@/modules/profile/components/ChangeEmail';
import {ChangePassword} from '@/modules/profile/components/ChangePassword';

export const ChangeEmailAndPassword = () => {
  return (
    <>
      <ChangeEmail />
      <ChangePassword />
    </>
  );
};
