import {BaseInput} from '@/components/inputs/form/BaseInput';
import {Label2} from '@/components/texts/Label';
import {useAppSelector} from '@/store';
import React, {forwardRef, useCallback, useEffect, useRef, useState} from 'react';
import {NativeSyntheticEvent, Platform, TextInputFocusEventData} from 'react-native';
import {GetProps, Input, View, composeRefs, styled} from 'tamagui';

type Props = {
  value?: number;
  maxValue?: number;
  onValueChange?: (value: number) => void;
  defaultValue?: number;
  decimal?: boolean;
  onAboveMax?: () => void;
  modal?: boolean;
} & Pick<
  GetProps<typeof BaseInput>,
  'onFocus' | 'onBlur' | 'bordered' | 'disabled' | 'id' | 'testID'
>;

export const AmountDecimalInput = forwardRef<Input, Props>(
  (
    {
      value,
      maxValue,
      onValueChange,
      defaultValue,
      decimal = true,
      onFocus,
      onBlur,
      onAboveMax,
      disabled,
      bordered,
      modal,
      ...rest
    },
    ref
  ) => {
    const locale = useAppSelector(state => state.app.language);
    const thousandSeparator = locale === 'de' ? '.' : ',';
    const decimalSeparator = locale === 'de' ? ',' : '.';
    const [innerValue, setInnerValue] = useState<string>('');
    const [focus, setFocus] = useState(false);

    const decimalIndex = useRef(-1);
    const prevValue = useRef<string>('');

    const inputRef = useRef<Input>(null);
    const mergedRefs = composeRefs(ref, inputRef);
    const [selection, setSelection] = useState({start: 0, end: 0});
    const updatedSelection = useRef<number>(0);

    const updateSelection = useCallback((oldVal: string, newVal: string) => {
      const diff = Math.abs(newVal.length - oldVal.length);
      const newPosition = updatedSelection.current + (diff === 0 ? 0 : diff - 1);
      if (isNaN(newPosition)) return;
      setSelection({
        start: newPosition,
        end: newPosition,
      });
    }, []);

    useEffect(() => {
      if (value === undefined) return;
      if (maxValue !== undefined && value > maxValue) {
        if (onValueChange) {
          onValueChange(maxValue);
        }

        if (onAboveMax) {
          onAboveMax();
        }

        return;
      }

      const formattedValue = Intl.NumberFormat(locale, {
        minimumFractionDigits: 0,
        maximumFractionDigits: decimal ? 2 : 0,
      }).format(value);
      prevValue.current = formattedValue;
      setInnerValue(formattedValue);
    }, [value, maxValue, locale, decimal, updateSelection, onValueChange, onAboveMax]);

    const handleFocus = useCallback(
      (event: NativeSyntheticEvent<TextInputFocusEventData>) => {
        if (disabled) return;
        if (onFocus) {
          onFocus(event);
        }
        setFocus(true);
      },
      [disabled, onFocus]
    );

    const handleBlur = useCallback(
      (event: NativeSyntheticEvent<TextInputFocusEventData>) => {
        if (disabled) return;
        if (onBlur) {
          onBlur(event);
        }
        setFocus(false);
      },
      [disabled, onBlur]
    );

    const createdRegx = (separator: string) =>
      new RegExp(separator === '.' ? `\\${separator}` : separator, 'g');

    const numberFormatting = (numberString: string, decimalIndex: number) => {
      const integerPart =
        decimalIndex === -1 ? numberString.slice() : numberString.slice(0, decimalIndex);
      const decimalPart = decimalIndex === -1 ? '' : numberString.slice(decimalIndex + 1);

      const normalizedIntegerPart = integerPart
        .replace(createdRegx(thousandSeparator), '')
        .replace(/\D/g, '');

      const normalizedDecimalPart = decimalPart.replace(/\D/g, '');
      const normalizedValueString = `${normalizedIntegerPart}.${normalizedDecimalPart}`;

      const normalizedValue = Math.round(Number.parseFloat(normalizedValueString) * 100) / 100;

      let formattedValue = Intl.NumberFormat(locale, {
        maximumFractionDigits: decimal ? 2 : 0,
      }).format(normalizedValue);

      const lastSymbolDecimal = decimalIndex === numberString.length - 1;
      const lastSymbolsDecimalWithZero =
        decimalIndex === numberString.length - 2 && numberString[numberString.length - 1] === '0';

      if (decimal && (lastSymbolDecimal || lastSymbolsDecimalWithZero)) {
        let lastSymbols = decimalSeparator;

        if (lastSymbolsDecimalWithZero) {
          lastSymbols = `${decimalSeparator}0`;
        }

        formattedValue = `${formattedValue}${lastSymbols}`;
      }

      return {
        normalizedValue,
        formattedValue,
      };
    };

    // TODO: @tlow92 Optimize all of these methods
    const handleChangeText = (rawValue: string) => {
      updatedSelection.current = (inputRef.current as unknown as any)?.selectionStart;

      if (innerValue === rawValue) return;

      if (!rawValue) {
        if (onValueChange) {
          onValueChange(0);
        }

        setInnerValue('0');

        decimalIndex.current = -1;

        return;
      }

      if (rawValue === '0') {
        if (onValueChange) {
          onValueChange(0);
        }

        setInnerValue(rawValue);

        decimalIndex.current = -1;

        return;
      }

      let updatedRawValue = rawValue;

      const prevValueDecimalSepIndex = prevValue.current.indexOf(decimalSeparator);

      if (
        decimalIndex.current > updatedRawValue.length - 1 ||
        !updatedRawValue.includes(decimalSeparator)
      ) {
        decimalIndex.current = -1;
      }

      const intPart =
        decimalIndex.current !== -1 ? rawValue.slice(0, decimalIndex.current) : rawValue;

      if (!Number.isSafeInteger(Number.parseInt(intPart.replace(/\D/g, ''), 10))) {
        return;
      }

      const prevTokens = prevValue.current.split(/\D/);
      const newTokens = rawValue.split(/\D/);

      if (newTokens.length > prevTokens.length) {
        if (prevValueDecimalSepIndex !== -1) return;

        const emptyIndex = newTokens.indexOf('');
        let newDecimalIndex = decimalIndex.current;
        let newFormattedIntPart = '';

        if (emptyIndex > -1) {
          newFormattedIntPart = Intl.NumberFormat(locale).format(
            Number.parseFloat(newTokens.slice(0, emptyIndex).join(''))
          );

          newDecimalIndex = newFormattedIntPart.length;
        } else {
          const newIntParts = [];

          for (const [i, newToken] of newTokens.entries()) {
            if (prevTokens[i] === newToken) {
              newIntParts.push(newToken);
            } else {
              newIntParts.push(newToken);

              break;
            }
          }

          newFormattedIntPart = Intl.NumberFormat(locale).format(
            Number.parseFloat(newIntParts.join(''))
          );
          newDecimalIndex = newFormattedIntPart.length;
        }

        updatedRawValue = `${newFormattedIntPart}${decimalSeparator}${newTokens[newTokens.length - 1]}`;
        decimalIndex.current = newDecimalIndex;
      }

      if (prevTokens.length === newTokens.length) {
        const copyNewTokens = [...newTokens];
        let decimalPart: string | undefined;

        if (prevValueDecimalSepIndex !== -1) {
          decimalPart = copyNewTokens.pop();
        }

        const newFormattedIntPart = Intl.NumberFormat(locale).format(
          Number.parseFloat(copyNewTokens.join(''))
        );

        if (prevValueDecimalSepIndex !== -1) {
          decimalIndex.current = newFormattedIntPart.length;
        }

        if (decimalPart) {
          if (decimalPart.length > 2) {
            return;
          }
          updatedRawValue = `${newFormattedIntPart}${decimalSeparator}${decimalPart}`;
        }
      }

      if (prevTokens.length > newTokens.length) {
        if (prevTokens[prevTokens.length - 1] === newTokens[newTokens.length - 1]) {
          const newFormattedIntPart = Intl.NumberFormat(locale).format(
            Number.parseFloat(newTokens.slice(0, -1).join(''))
          );
          decimalIndex.current = newFormattedIntPart.length;
          updatedRawValue = `${newFormattedIntPart}${decimalSeparator}${
            newTokens[newTokens.length - 1]
          }`;
        } else {
          decimalIndex.current = -1;
        }
      }

      const {normalizedValue, formattedValue} = numberFormatting(
        updatedRawValue,
        decimalIndex.current
      );

      if (Number.isNaN(normalizedValue)) {
        if (onValueChange) {
          onValueChange(0);
        }

        decimalIndex.current = -1;

        return;
      }

      const decimalCheckRegexp = new RegExp(
        `^\\d{1,3}(${thousandSeparator}\\d{3})*(${decimalSeparator}(\\d{0,2})?)?$`,
        'g'
      );

      if (decimal && !decimalCheckRegexp.test(formattedValue)) {
        return;
      }

      updateSelection(prevValue.current, formattedValue);
      prevValue.current = formattedValue;
      setInnerValue(formattedValue);

      if (onValueChange) {
        onValueChange(normalizedValue);
      }
    };

    return (
      <View>
        <StyledEuroLabel bordered={bordered}>€</StyledEuroLabel>
        <BaseInput
          ref={mergedRefs}
          value={innerValue}
          onChangeText={handleChangeText}
          defaultValue={defaultValue ? Intl.NumberFormat(locale).format(defaultValue) : undefined}
          onFocus={handleFocus}
          onBlur={handleBlur}
          selection={Platform.OS === 'web' ? selection : undefined}
          disabled={disabled}
          bordered={bordered}
          modal={modal}
          $sm={{
            // fixes euro sign overlapping input on mobile
            paddingRight: bordered ? '$6' : '$8',
          }}
          keyboardType="numeric"
          {...rest}
        />
      </View>
    );
  }
);

const StyledEuroLabel = styled(Label2, {
  userSelect: 'none',
  pointerEvents: 'none',
  paddingRight: 0,
  color: '$neutral400',
  position: 'absolute',
  paddingVertical: '$5',
  right: '$5',
  zIndex: 1,
  top: 0,
  bottom: 0,
  alignSelf: 'center',
  '$platform-native': {
    bottom: 0,
    top: 'auto',
  },

  variants: {
    bordered: {
      true: {
        paddingVertical: '$3',
        right: '$3',
        fontFamily: '$body',
        fontSize: 14,
        lineHeight: 18,
        $sm: {
          fontSize: 12,
          lineHeight: 16,
        },
      },
      false: {
        fontFamily: '$bodyMedium',
        fontSize: 16,
        lineHeight: 22,
        $sm: {
          fontSize: 14,
          lineHeight: 18,
          paddingVertical: '$4',
        },
      },
    },
  } as const,
  defaultVariants: {
    bordered: false,
  },
});
